import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  landingIndex: 0, // keeps track of current landing index
  jumpSection: 0, // when bumped, the watcher on landing section triggers new section animation instead of scrolling version
  animationType: 'slow',
  canTransition: true, // handles local transitions on landing header section
  caseStudyIndex: 0,
};

export const slice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setCanTransition: (state, { payload }) => {
      state.canTransition = payload;
    },
    setLandingIndex: (state, { payload }) => {
      state.landingIndex = payload;
    },
    setCaseStudyIndex: (state, { payload }) => {
      // console.log(state.caseStudyIndex, payload)
      state.caseStudyIndex = payload;
    },
    bumpJumpSection: (state) => {
      state.jumpSection++;
    },

    setAnimationType: (state, { payload }) => {
      state.animationType = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLandingIndex,
  setCaseStudyIndex,
  bumpJumpSection,
  setAnimationType,
  setCanTransition,
} = slice.actions;

export default slice.reducer;
