import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  menuAnimationTime: 0.5,
  menuOpen: false,
  canToggleMenu: true,
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    openMenu: (state) => {
      state.menuOpen = true;
    },
    closeMenu: (state) => {
      state.menuOpen = false;
    },
    setCanToggleMenu: (state, { payload }) => {
      state.canToggleMenu = payload;
    },
    toggleMenu: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.menuOpen = !state.menuOpen;
    },
  },
});

// Action creators are generated for each case reducer function
export const { toggleMenu, openMenu, closeMenu, setCanToggleMenu } =
  uiSlice.actions;

export default uiSlice.reducer;
