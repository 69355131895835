import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  caseStudies: [],
};

export const slice = createSlice({
  name: 'caseStudies',
  initialState,
  reducers: {
    setCaseStudies: (state, { payload }) => {
      state.caseStudies = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCaseStudies } = slice.actions;

export default slice.reducer;
