import {
  configureStore as reduxConfigureStoreRedux,
  combineReducers,
} from '@reduxjs/toolkit';
import uiReducer from './ui';
import navReducer from './nav';
import homeReducer from './home';
import testimonialsReducer from './testimonials';
import faqReducer from './faqs';
import instagramPostsReducer from './instagramPosts';
import caseStudiesReducer from './caseStudies';
import jobsReduser from './jobs';
import preloadingReducer from './preloading';

const rootReducer = combineReducers({
  ui: uiReducer,
  nav: navReducer,
  home: homeReducer,
  caseStudies: caseStudiesReducer,
  testimonials: testimonialsReducer,
  faqs: faqReducer,
  instagramPosts: instagramPostsReducer,
  jobs: jobsReduser,
  preloading: preloadingReducer
});

const configureStore = () => reduxConfigureStoreRedux({ reducer: rootReducer });
export default configureStore;
