import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  jobs: null
};

export const slice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    setJobs: (state, { payload }) => {
        const uk = payload.requisitions.filter(r => r.region === 'United Kingdom');
        const ire = payload.requisitions.filter(r => r.region === 'Ireland');
        const sa = payload.requisitions.filter(r => r.region === 'South Africa');
        const allJobs = [
          {
            name: 'South Africa',
            jobs: sa
          },
          {
            name: 'Ireland',
            jobs: ire
          },          
          { 
            name: 'United Kingdom',
            jobs: uk
          },
        ];

        // Place 0 jobs at bottom of array
        allJobs.sort((a,b) => {
          if (a.jobs.length === 0) return 1;
          if (b.jobs.length === 0) return -1;
          return a.jobs.length - b.jobs.length;
        });

        state.jobs = allJobs;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setJobs } = slice.actions;

export default slice.reducer;
