import { createSlice, createSelector } from '@reduxjs/toolkit';

const initialState = {
  onHomeScrollable: false,
  onLandingSection: false,
  onWhatWeDoSection: false,
  onCaseStudiesSection: false,
  onOurClientsSection: false,
  onFooterSection: false,
  activeTransitions: [],
};

export const navSlice = createSlice({
  name: 'nav',
  initialState,
  reducers: {
    setOnHomeScrollable: (state, { payload }) => {
      state.onHomeScrollable = payload;
    },
    setOnLandingSection: (state, { payload }) => {
      state.onLandingSection = payload;
    },
    setOnWhatWeDoSection: (state, { payload }) => {
      state.onWhatWeDoSection = payload;
    },
    setOnCaseStudiesSection: (state, { payload }) => {
      state.onCaseStudiesSection = payload;
    },
    setOnOurClientsSection: (state, { payload }) => {
      state.onOurClientsSection = payload;
    },
    setOnFooterSection: (state, { payload }) => {
      state.onFooterSection = payload;
    },
    addTransition: (state, { payload }) => {
      let temp = state.activeTransitions;
      temp.push(payload);

      state.activeTransitions = Array.from(new Set(temp));
    },
    removeTransition: (state, { payload }) => {
      let temp = state.activeTransitions.filter((item) => item !== payload);
      state.activeTransitions = Array.from(new Set(temp));
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setOnHomeScrollable,
  setOnLandingSection,
  setOnWhatWeDoSection,
  setOnCaseStudiesSection,
  setOnOurClientsSection,
  setOnFooterSection,
  setActiveTransitions,
  addTransition,
  removeTransition,
} = navSlice.actions;

export default navSlice.reducer;
