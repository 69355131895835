import { createSlice } from '@reduxjs/toolkit';

const initialState = {
   posts: null,
};

export const slice = createSlice({
  name: 'instagramPosts',
  initialState,
  reducers: {
    setInstagramPosts: (state, { payload }) => {
      state.posts = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setInstagramPosts } = slice.actions;

export default slice.reducer;
