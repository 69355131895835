import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  faqs: null,
};

export const slice = createSlice({
  name: 'faqs',
  initialState,
  reducers: {
    setFAQs: (state, { payload }) => {
      state.faqs = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setFAQs } = slice.actions;

export default slice.reducer;
